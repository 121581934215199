import React from 'react'
import styled, { keyframes } from 'styled-components'

const VerticalMarqueeMobile = ({ children }) => (
  <Marquee>
    {children}
    {children}
  </Marquee>
)

const marqueeAnimationMobile = keyframes`
    0% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(-75%);
`
const Marquee = styled.div`
  @media (max-width: 650px) {
    transform: translateX(-25%);
    animation: ${marqueeAnimationMobile} 12s linear infinite;
    display: flex;
    height: auto;
    width: 2000%;
    flex-direction: row;
  }
`

export default VerticalMarqueeMobile
