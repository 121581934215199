import React, { useContext, useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import { ButtonContext } from "../context/button-context"

import FaqItemComponent from "./faqItem"

export const fragmentQl = graphql`
  fragment FaqListFragment on WordPress_Page_Faqfields {
    questions {
      questionList {
        text
        title
      }
    }
  }
`

const FaqList = ({ faqList }) => {
  const triggerRef = useRef()
  const [isDesktop, setIsDesktop] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [buttonTriggers, setButtonTriggers] = useContext(ButtonContext)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const titleAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px) rotateX(10deg)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px) rotateX(0deg)` },
    delay: 1000,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  const textAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px) rotateX(10deg)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px) rotateX(0deg)` },
    delay: 1100,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  return (
    <Outer>
      <WrapFaqList>
        {faqList.questionList.map((faq, index) => (
          <FaqItemComponent
            // key={faq.title}
            title={faq.title}
            text={faq.text}
            index={index}
          />
        ))}
      </WrapFaqList>
    </Outer>
  )
}

export default FaqList

const Outer = styled.section`
  position: relative;
  min-height: 100vh;
  height: auto;
  overflow: hidden;
  z-index: 20;
  padding: 10rem 0 15rem 0;
  background-color: ${props => props.theme.colours.white};

  @media (max-width: 1024px) {
    height: auto;
    min-height: 100vh;
  }

  @media (max-width: 650px) {
    min-height: auto;
    padding: 0;
    margin-bottom: 7.5rem;
    height: auto;
  }
`

const WrapFaqList = styled.div`
  margin: 0;
  padding: 0 10%;

  @media (max-width: 650px) {
    padding: 0 3rem;
  }
`

const FaqItem = styled.div`
  margin: 0;
`

const FaqTitle = styled.h3`
  margin: 0;

  p {
    font-size: 4rem;
    font-weight: 700;
    font-family: ${props => props.theme.fonts.sofia};
    color: ${props => props.theme.colours.grey};
  }
`

const FaqText = styled.div`
  margin: 0;
  max-width: 125ch;

  p {
    font-size: 1.6rem;
    font-family: ${props => props.theme.fonts.circular};
    color: ${props => props.theme.colours.grey};

    a {
      color: ${props => props.theme.colours.grey};
      text-decoration: none;
    }
  }
`
