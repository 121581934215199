import React, { useContext, useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import { ButtonContext } from "../context/button-context"
import InView from "../in-view"

import HeroFlowmap from "../hero-flowmap"

export const fragmentQl = graphql`
  fragment FaqHeroFragment on WordPress_Page_Faqfields {
    heroInfos {
      image {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          publicURL
        }
      }
      imageMobile {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      title
      subtitle
    }
  }
`

const FaqHero = ({ hero }) => {
  const triggerRef = useRef()
  const [isDesktop, setIsDesktop] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [buttonTriggers, setButtonTriggers] = useContext(ButtonContext)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const titleAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px) rotateX(10deg)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px) rotateX(0deg)` },
    delay: 1000,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  const textAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px) rotateX(10deg)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px) rotateX(0deg)` },
    delay: 1100,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  return (
    <Outer>
      {!isDesktop && (
        <InView
          element={triggerRef.current}
          setToggle={setButtonTriggers}
          location="hero"
        >
          <TriggerButton ref={triggerRef} />
        </InView>
      )}
      <WrapHero>
        <HeroFlowmap
          mobile={hero.imageMobile.imageFile.childImageSharp.fluid}
          desktop={hero.image.imageFile.publicURL}
        />
        <WrapText>
          <animated.h1 style={titleAnimation}>{hero.title}</animated.h1>
          <animated.div
            style={textAnimation}
            dangerouslySetInnerHTML={{ __html: hero.subtitle }}
          ></animated.div>
        </WrapText>
      </WrapHero>
    </Outer>
  )
}

export default FaqHero

const Outer = styled.section`
  position: relative;
  min-height: 100vh;
  height: auto;
  overflow: hidden;
  z-index: 20;

  @media (max-width: 1024px) {
    height: auto;
    min-height: 100vh;
  }

  @media (max-width: 650px) {
    margin-bottom: 7.5rem;
    height: auto;
  }
`

const TriggerButton = styled.div`
  position: absolute;
  top: 20%;
  width: 100%;
  height: 1px;
  background-color: transparent;
`

const WrapHero = styled.div`
  position: relative;
  height: 100%;
`

const WrapText = styled.div`
  position: absolute;
  width: calc(46% - 15rem);
  height: auto;
  top: 50%;
  right: 16rem;
  transform: translateY(-50%);

  @media (max-width: 1400px) {
    position: absolute;
    width: calc(50% - 10rem);
    height: auto;
    top: 50%;
    right: 10rem;
    transform: translateY(-50%);
  }

  @media (max-width: 1024px) {
    position: relative;
    top: 0;
    left: 0;
    /* width: 80%; */
    width: 66%;
    margin: 5rem auto;
    transform: translateY(0);
    text-align: center;
  }

  @media (max-width: 650px) {
    top: 5rem;
  }

  h1 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 5.5rem;
    line-height: 1.15;
    letter-spacing: -0.075rem;
    margin-bottom: 3rem;
    max-width: 17ch;

    @media (max-width: 1024px) {
      font-size: 5rem;
      line-height: 1.1;
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: 650px) {
      margin-bottom: 5rem;
    }
  }

  p {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 300;
    font-size: 2.2rem;
    line-height: 1.55;
    max-width: 35ch;

    @media (max-width: 1024px) {
      font-size: 2.2rem;
      margin: 0 auto;
    }

    @media (max-width: 650px) {
      font-size: 2.75rem;
      line-height: 1.5;
    }
  }
`
