import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"

import BezierEasing from "bezier-easing"

const FaqItemComponent = ({ title, text, index }) => {
  const [isDesktop, setIsDesktop] = useState()
  const [on, toggle] = useState(false)
  const descRef = useRef()

  const animation = useSpring({
    overflow: "hidden",
    maxHeight: on ? `${descRef.current.offsetHeight}px` : "0px",
    config: {
      duration: 750,
      easing: BezierEasing(0.37, 0, 0.63, 1),
    },
  })

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  return (
    <JobItem key={title}>
      <WrapJobTitle onClick={() => toggle(!on)}>
        <MoreWrap>
          <animated.span className={`wrap-plus ${on ? "open" : "close"}`}>
            <span className="vertical-bar"></span>
            <span className="horizontal-bar"></span>
          </animated.span>
        </MoreWrap>
        <JobTitle dangerouslySetInnerHTML={{ __html: title }} />
      </WrapJobTitle>
      <HiddenDropdown style={animation}>
        <JobDescription
          ref={descRef}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </HiddenDropdown>
    </JobItem>
  )
}

export default FaqItemComponent

const JobItem = styled.div`
  margin: 2rem 0;
  /* border-bottom: 1px solid ${props => props.theme.colours.white}; */
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  /* padding: 0 0 4.5rem 0; */
  
  &:first-child {
    /* border-top: 1px solid ${props => props.theme.colours.white}; */
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
`

const HiddenDropdown = styled(animated.div)`
  color: ${props => props.theme.colours.white};
`

const JobDescription = styled.div`
  max-width: 75%;
  margin-bottom: 3rem;

  @media (max-width: 1024px) {
    max-width: 100%;
    margin-bottom: 4rem;
  }

  p {
    color: ${props => props.theme.colours.grey};
    font-size: 1.6rem;

    @media (max-width: 1024px) {
      font-size: 2.2rem;
    }

    @media (max-width: 650px) {
      font-size: 2.2rem;
    }
  }

  a {
    color: ${props => props.theme.colours.pink};
    text-decoration: none;
  }

  ul {
    list-style: none;
    width: 100%;
  }

  li {
    position: relative;
    font-size: 1.6rem;
    line-height: 1.8;
    font-family: ${props => props.theme.fonts.circular};
    color: ${props => props.theme.colours.grey};
    padding-left: 3rem;

    @media (max-width: 650px) {
      font-size: 2.2rem;
    }

    &:before {
      position: absolute;
      top: 8px;
      left: -1rem;
      content: "";
      width: 1.3rem;
      height: 1.3rem;
      background-color: ${props => props.theme.colours.pink};
      border-radius: 50%;
    }
  }
`

const WrapJobTitle = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
  cursor: pointer;
  /* padding: 2.5rem 0 0 0; */
  padding: 0;
`

const JobTitle = styled.h3`
  /* @media (max-width: 650px) { */
  margin-bottom: 3rem;
  /* } */

  p {
    font-family: ${props => props.theme.fonts.sofia};
    font-weight: 700;
    font-size: 3.5rem;
    line-height: 1.25;
    color: ${props => props.theme.colours.grey};
    margin-bottom: 0;

    @media (max-width: 650px) {
      font-size: 2.9rem;
    }
  }
`

const MoreDetailSpan = styled.span``

const MoreWrap = styled.div`
  display: flex;
  /* align-items: center;
  justify-content: center; */
  margin: 0.66rem 2.5rem 0 0;
  color: ${props => props.theme.colours.pink};
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;

  @media (max-width: 650px) {
    margin: 0 3rem 0 0;
  }

  .wrap-plus {
    position: relative;
    width: 35px;
    height: 35px;
    background-color: ${props => props.theme.colours.pink};
    border-radius: 50%;

    @media (max-width: 650px) {
      width: 27px;
      height: 27px;
    }

    &.open {
      .vertical-bar {
        opacity: 0;
      }
    }
  }

  .horizontal-bar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${props => props.theme.colours.white};
    height: 3px;
    width: 16px;

    @media (max-width: 650px) {
      width: 12px;
    }
  }

  .vertical-bar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    background-color: ${props => props.theme.colours.white};
    height: 3px;
    width: 16px;
    transition: opacity 0.5s ease-in-out;

    @media (max-width: 650px) {
      width: 12px;
    }
  }

  span {
    /* line-height: 0;
    display: inline-block;
    margin-left: 1rem; */

    @media (max-width: 650px) {
      svg {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }

  & ${MoreDetailSpan} {
    display: block;

    @media (max-width: 650px) {
      display: none;
    }
  }
`
