import React from 'react'
import styled, { keyframes } from 'styled-components'

const VerticalMarquee = ({ children }) => (
  <Marquee>
    {children}
    {children}
    {children}
  </Marquee>
)

const marqueeAnimation = keyframes`
    0% {
    transform: translateY(-33.3333%);
  }

  100% {
    transform: translateY(-67%);
  }
`

const marqueeAnimationMobile = keyframes`
    0% {
    transform: translateX(-33.3333%);
  }

  100% {
    transform: translateX(-67%);
`
const Marquee = styled.div`
  transform: translateY(-33.3333%);
  animation-name: ${marqueeAnimation};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
  height: fit-content;
  animation-duration: 25s;

  @media (max-width: 650px) {
    transform: translateX(-33.3333%);
    /* animation-timing-function: linear;
    animation-iteration-count: infinite; */
    animation-name: ${marqueeAnimationMobile};
    animation-play-state: running;
    animation-duration: 20s;
    display: flex;
    height: auto;
    width: 3000%;
    flex-direction: row;
    will-change: transform;
  }
`

export default VerticalMarquee
