import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext,
} from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import FaqHero from "../components/faq/hero"
import FaqList from "../components/faq/faqList"
import FaqArticles from "../components/faq/articles"
import FaqGetInTouch from "../components/faq/start-project"
import InView from "../components/in-view"
import { ButtonContext } from "../components/context/button-context"
import SEO from "../components/seo"

const Faq = ({ data, pageContext }) => {
  const [isDesktop, setIsDesktop] = useState(null)
  const [buttonTriggers, setButtonTriggers] = useContext(ButtonContext)
  const sectionRef = useRef()
  const articlesRef = useRef()

  function handleResize() {
    setIsDesktop(window.innerWidth > 1024)
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [isDesktop])

  return (
    <Layout>
      <SEO
        pageTitle={pageContext.title}
        slug={pageContext.slug}
        {...data.wordPress.pageBy.seo}
      />
      <FaqHero hero={data.wordPress.pageBy.faqFields.heroInfos} />
      <FaqList faqList={data.wordPress.pageBy.faqFields.questions} />
      <InView
        element={sectionRef.current}
        setToggle={setButtonTriggers}
        location="cracking"
      >
        <div ref={sectionRef}>
          <FaqGetInTouch
            faqGetInTouch={data.wordPress.pageBy.faqFields.getInTouch}
            data={data.wordPress.globalOptions}
          />
        </div>
      </InView>
      <FaqArticles
        articleList={data.wordPress.pageBy.faqFields.usefullArticles}
      />
    </Layout>
  )
}

export default Faq

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      pageBy(pageId: $wordpressId) {
        ...SeoFragment
        faqFields {
          ...FaqHeroFragment
          ...FaqListFragment
          ...FaqRelatedFragment
          ...FaqStartFragment
        }
      }
      globalOptions {
        optionFields {
          contactInfos {
            emailProjects
            phone
            phoneNyc
          }
          startProject {
            ctaText
            formTitle
            introText
            sideText
          }
        }
      }
    }
  }
`
